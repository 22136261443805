/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-props-no-spreading */
import { Button, Form, Modal } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import React, { useState } from 'react';
import { defaults } from 'lodash';
import setStateFromInput from '../../../Utils/setStateFromInput';
// import BookingService from '../../../Services/BookingService';
import FormControlFeedback from '../../../Components/FormControlFeedback';
import Error from '../../../Components/Error';
import BookingService from '../../../Services/BookingService';

function AddInvoiceModal({
  originalOrder = {}, show = false, handleClose,
}) {
  const defaultOrder = {
    email: originalOrder.email_address,
    file: null,
  };

  const [order, setOrder] = useState(defaults(originalOrder, defaultOrder));
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [validationErrors, setValidationErrors] = useState([]);
  const [fileState, setFileState] = useState(null);

  const handleChange = (e) => {
    setStateFromInput(e, setOrder);
  };

  const handleDrop = (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      setFileState(file);

      const reader = new FileReader();
      reader.onload = () => {
        const base64File = reader.result.split(',')[1]; // Extract Base64 data
        setOrder((prevOrder) => ({
          ...prevOrder,
          file: base64File, // Save the Base64 string to state
        }));
      };
      reader.readAsDataURL(file); // Read file as Base64
    }
  };

  const handleSubmit = async () => {
    setLoading(true);

    try {
      const data = {
        _id: order._id,
        email: order.email,
        file: order.file,
      };
      await BookingService.addInvoice(data);
      handleClose();
    } catch (err) {
      setError(err.response.data?.message);

      if (err.response.data.errors) {
        setValidationErrors(err.response.data?.errors);
      }
    } finally {
      setLoading(false);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop,
    accept: '.pdf',
    multiple: false,
  });

  return (
    <Modal show={show} onHide={handleClose} size="md">
      <Modal.Header closeButton>
        <Modal.Title>Add Invoice</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Error error={error} setError={setError} />

        <div className="row mb-4">
          <Form.Group className="col-12" controlId="email">
            <Form.Label>Email(s)</Form.Label>
            <Form.Control
              value={order.email}
              placeholder="customer@gmail.com,customer2@gmail.com"
              onChange={(e) => handleChange(e)}
            />
            <FormControlFeedback errors={validationErrors} field="email" />
          </Form.Group>
        </div>

        <div
          {...getRootProps()} // Correctly applying getRootProps
          style={{
            border: '2px dashed #ccc',
            borderRadius: '5px',
            padding: '20px',
            textAlign: 'center',
            cursor: 'pointer',
          }}
        >
          <input {...getInputProps()} />
          {' '}
          {/* Correctly applying getInputProps */}
          {isDragActive ? (
            <p>Drop the file here...</p>
          ) : (
            <p>
              Drag and drop an invoice file here, or click to select one.
              <br />
              (Only PDF files are accepted)
            </p>
          )}
          {order.file && (
          <p>
            Selected file:
            {fileState.name}
          </p>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleSubmit} disabled={loading}>
          Add Invoice
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AddInvoiceModal;
