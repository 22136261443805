/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import {
  Modal, Form, Button,
} from 'react-bootstrap';
import _ from 'lodash';
import { Typeahead } from 'react-bootstrap-typeahead';
import Error from '../../../Components/Error';
import FormControlFeedback from '../../../Components/FormControlFeedback';
import BookingService from '../../../Services/BookingService';
import setStateFromInput from '../../../Utils/setStateFromInput';
import OperatorService from '../../../Services/OperatorService';

function DriverInputModal({
  originalOrder = {}, show = false, handleClose, fetchOrders,
}) {
  const defaultOrder = {
    operator_driver_id: '',
    operator_car_id: '',
    notify_email: originalOrder.email_address,
    send_driver_phone_number: false,
  };

  const [order, setOrder] = useState(_.defaults(originalOrder, defaultOrder));

  const defaultDriver = {
    operator_id: order.operator_id,
    active: 1,
  };

  const defaultCar = {
    operator_id: order.operator_id,
    active: 1,
  };

  const [originalOrderState] = useState(order);
  const [loading, setLoading] = useState(false);
  const [showAddDriver, setShowAddDriver] = useState(false);
  const [showAddCar, setShowAddCar] = useState(false);
  const [drivers, setDrivers] = useState([]);
  const [driver, setDriver] = useState(_.defaults({}, defaultDriver));
  const [cars, setCars] = useState([]);
  const [car, setCar] = useState(_.defaults({}, defaultCar));
  const [error, setError] = useState('');
  const [validationErrors, setValidationErrors] = useState([]);

  const handleChange = (e) => {
    setStateFromInput(e, setOrder);
  };

  const decorateDriverOption = (option) => {
    if (!option) return null;

    return {
      id: option.Id,
      label: `${option.full_name} - ${option.phone_number}`,
    };
  };

  const [selectedDriver, setSelectedDriver] = useState(order.driver ? [decorateDriverOption(order.driver)] : '');

  const decorateCarOption = (option) => {
    if (!option) return null;

    return {
      id: option.Id,
      label: `${option.plate_number || ''} - ${option.type || ''} - ${option.color || ''}`,
    };
  };

  const [selectedCar, setSelectedCar] = useState(order.car ? [decorateCarOption(order.car)] : '');

  const handleSubmit = async () => {
    setLoading(true);

    try {
      const data = {
        operator_driver_id: selectedDriver[0].id || '',
        operator_car_id: selectedCar[0].id || '',
        _id: order._id,
        notify_email: order.notify_email,
        send_driver_phone_number: order.send_driver_phone_number,
      };
      await BookingService.assignDriverAndCar(data);
      fetchOrders();
      handleClose();
    } catch (err) {
      setError(err.response.data?.message);

      if (err.response.data.errors) {
        setValidationErrors(err.response.data?.errors);
      }
    } finally {
      setLoading(false);
    }
  };

  const fetchDrivers = async () => {
    setLoading(true);
    try {
      const response = await OperatorService.fetchDrivers(order.operator_id);
      const driversRaw = response?.data.data?.drivers || [];
      const driversTypeahead = [];

      driversRaw.forEach((driverRaw) => {
        driversTypeahead.push(
          decorateDriverOption(driverRaw),
        );
      });

      setDrivers(driversTypeahead);
    } catch (err) {
      setError('Unable to fetch drivers');
    } finally {
      setLoading(false);
    }
  };

  const fetchCars = async () => {
    setLoading(true);
    try {
      const response = await OperatorService.fetchCars(order.operator_id);
      const carsRaw = response?.data.data?.cars || [];
      const carsTypeahead = [];

      carsRaw.forEach((driverRaw) => {
        carsTypeahead.push(
          decorateCarOption(driverRaw),
        );
      });

      setCars(carsTypeahead);
    } catch (err) {
      setError('Unable to fetch cars');
    } finally {
      setLoading(false);
    }
  };

  const debouncedFetchDrivers = _.debounce(() => {
    fetchDrivers();
  }, 500);

  const debouncedFetchCars = _.debounce(() => {
    fetchCars();
  }, 500);

  const handleAddDriver = async () => {
    setLoading(true);

    try {
      await OperatorService.addDriver(driver);
      setDriver(_.defaults({}, defaultDriver));
      setShowAddDriver(false);
      debouncedFetchDrivers();
    } catch (err) {
      setError(err.response.data?.message);

      if (err.response.data.errors) {
        setValidationErrors(err.response.data?.errors);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleAddCar = async () => {
    setLoading(true);

    try {
      await OperatorService.addCar(car);
      setCar(_.defaults({}, defaultCar));
      setShowAddCar(false);
      debouncedFetchCars();
    } catch (err) {
      setError(err.response.data?.message);

      if (err.response.data.errors) {
        setValidationErrors(err.response.data?.errors);
      }
    } finally {
      setLoading(false);
    }
  };

  /**
   * Handle driver change
   * @param {object} e
   */
  const handleDriverChange = (e) => {
    setStateFromInput(e, setDriver, order);
  };

  /**
   * Handle car change
   * @param {object} e
   */
  const handleCarChange = (e) => {
    setStateFromInput(e, setCar, order);
  };

  useEffect(() => {
    debouncedFetchDrivers();
    debouncedFetchCars();
  }, [originalOrderState]);

  useEffect(() => {
    let newState;
    if (Array.isArray(selectedDriver) && selectedDriver.length === 1) {
      newState = {
        ...newState,
        operator_driver_id: selectedDriver[0].id,
      };
    }

    if (Array.isArray(selectedCar) && selectedCar.length === 1) {
      newState = {
        ...newState,
        operator_car_id: selectedCar[0].id,
      };
    }

    setOrder((prevState) => ({ ...prevState, ...newState }));
  }, [selectedDriver, selectedCar]);

  return (
    <Modal show={show} onHide={handleClose} size="md">
      <Modal.Header closeButton>
        <Modal.Title>
          Assign Driver & Car
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>

        <Error
          error={error}
          setError={setError}
        />

        <div className="row">
          <Form.Group className="mb-3 col-12" controlId="operator_driver_id">
            <Form.Label>Driver</Form.Label>
            <Typeahead
              id="operator_driver_id"
              onChange={setSelectedDriver}
              options={drivers}
              selected={selectedDriver}
            />
            <FormControlFeedback errors={validationErrors} field="operator_driver_id" />
          </Form.Group>

          <div className="mb-1 col-12">
            <Button variant="secondary" onClick={() => setShowAddDriver(true)}>+ Add Driver</Button>

            { showAddDriver && (
            <div className="mt-1 mb-3 row">
              <Form.Group className="mt-2 col-12 col-lg-5" controlId="full_name">
                <Form.Label>Full Name</Form.Label>
                <Form.Control
                  value={driver.full_name}
                  onChange={(e) => handleDriverChange(e)}
                />

                <FormControlFeedback errors={validationErrors} field="full_name" />
              </Form.Group>

              <Form.Group className="mt-2 col-12 col-lg-5" controlId="phone_number">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  value={driver.phone_number}
                  onChange={(e) => handleDriverChange(e)}
                />

                <FormControlFeedback errors={validationErrors} field="phone_number" />
              </Form.Group>

              <div className="mt-2 col-12 col-lg-2 align-self-end">
                <Button
                  variant="secondary"
                  onClick={handleAddDriver}
                  disabled={loading}
                >
                  Add
                </Button>
              </div>

            </div>

            )}
          </div>
        </div>

        <div className="row mt-3">
          <Form.Group className="mb-3 col-12" controlId="operator_car_id">
            <Form.Label>Car</Form.Label>
            <Typeahead
              id="operator_car_id"
              onChange={setSelectedCar}
              options={cars}
              selected={selectedCar}
            />
            <FormControlFeedback errors={validationErrors} field="operator_car_id" />
          </Form.Group>

          <div className="mb-1 col-12">
            <Button variant="secondary" onClick={() => setShowAddCar(true)}>+ Add Car</Button>

            { showAddCar && (
            <div className="mt-1 mb-3 row">
              <Form.Group className="mt-2 col-12" controlId="type">
                <Form.Label>Type</Form.Label>
                <Form.Control
                  placeholder="Skoda Superb"
                  value={car.full_name}
                  onChange={(e) => handleCarChange(e)}
                />

                <FormControlFeedback errors={validationErrors} field="type" />
              </Form.Group>

              <Form.Group className="mt-2 col-12" controlId="color">
                <Form.Label>Color</Form.Label>
                <Form.Control
                  placeholder="White"
                  value={car.color}
                  onChange={(e) => handleCarChange(e)}
                />

                <FormControlFeedback errors={validationErrors} field="color" />
              </Form.Group>

              <Form.Group className="mt-2 col-12" controlId="plate_number">
                <Form.Label>Plate Number</Form.Label>
                <Form.Control
                  placeholder="WWW-111"
                  value={car.plate_number}
                  onChange={(e) => handleCarChange(e)}
                />

                <FormControlFeedback errors={validationErrors} field="plate_number" />
              </Form.Group>

              <div className="mt-2 col-12 col-lg-2 align-self-end">
                <Button
                  variant="secondary"
                  onClick={handleAddCar}
                  disabled={loading}
                >
                  Add
                </Button>
              </div>

            </div>

            )}
          </div>

          <div className="row mb-4 mt-3">
            <Form.Group className="col-8" controlId="notify_email">
              <Form.Label>Send Notificaton to Email(s)</Form.Label>
              <Form.Control
                placeholder="customer@domain.com,customer2@gmail.com"
                type="email"
                value={order.notify_email}
                onChange={(e) => handleChange(e)}
              />

              <FormControlFeedback errors={validationErrors} field="notify_email" />
            </Form.Group>

            <Form.Group className="col-4" controlId="send_driver_phone_number">
              <Form.Label>Driver Phone</Form.Label>
              <Form.Check
                disabled={order.notify_email === ''}
                value={order.send_driver_phone_number}
                onChange={(e) => handleChange(e)}
              />

              <FormControlFeedback errors={validationErrors} field="send_driver_phone_number" />
            </Form.Group>
          </div>
        </div>

        <Modal.Footer>
          <Button
            onClick={handleSubmit}
            disabled={loading}
          >
            Assign
            {' '}
            {order.notify_email ? 'with Notification' : ''}
          </Button>
        </Modal.Footer>
      </Modal.Body>
    </Modal>
  );
}

export default DriverInputModal;
