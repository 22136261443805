/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { Modal, Table } from 'react-bootstrap';
import format from '../../../Utils/dateFormat';
import thousandSeparator from '../../../Utils/numberFormat';

export default function TripConfirmationModal({ order, show, handleClose }) {
  const printModalContent = () => {
    const modal = document.querySelector('.modal .modal-content .modal-body');
    if (!modal) {
      console.error('Modal element not found.');
      return;
    }

    const modalContent = modal.innerHTML;
    const printWindow = window.open('', '', 'height=800,width=600');

    if (!printWindow) {
      console.error('Failed to open print window.');
      return;
    }

    printWindow.document.write(`<html><head><title>Trip Confirmation - ${order.booking_reference}</title>`);
    printWindow.document.write('<style>');
    printWindow.document.write(`
          /* General Styling */
          body, html {
            font-family: "Arial", sans-serif;
            line-height: 1.6;
            margin: 0;
            padding: 0;
            color: #212529;
            background-color: #ffffff;
          }

          h1, h2, h3 {
            margin: 0 0 10px 0;
            color: #343a40;
          }

          a {
            color: #007bff;
            text-decoration: none;
          }

          a:hover {
            text-decoration: underline;
          }

          /* Modal Header */
          .modal-header {
            border-bottom: 2px solid #dee2e6;
            padding: 15px 20px;
          }

          .modal-title {
            font-size: 1.5rem;
            font-weight: bold;
          }

          /* Logo Section */
          img {
            max-width: 100%;
            height: auto;
          }

          h1 {
            font-size: 2rem;
            color: #eba23a;
            text-align: center;
            margin-top: 10px;
          }

          /* Section Headers */
          h2 {
            font-size: 1.5rem;
            margin-bottom: 15px;
          }

          h3 {
            font-size: 1.25rem;
            margin-bottom: 10px;
            color: #dc3545;
          }

          /* Important Information Section */
          .important-info {
            background: #f8f9fa;
            padding: 15px;
            border-radius: 5px;
            margin-bottom: 2rem;
          }

          .important-info ul {
            list-style: circle;
            padding-left: 20px;
            margin: 0;
          }

          .important-info li {
            margin-bottom: 10px;
          }

          /* Booking Info Section */
          .booking-info {
            margin-bottom: 1.5rem;
            padding-bottom: 10px;
            border-bottom: 2px solid #dee2e6;
          }

          .booking-info p {
            margin: 5px 0;
          }

          /* Table Styling */
          table {
            width: 100%;
            border-collapse: collapse;
            margin-top: 20px;
            font-size: 0.9rem;
          }

          table th, table td {
            padding: 10px;
            text-align: left;
            border: 1px solid #dee2e6;
          }

          table th {
            background-color: #f1f1f1;
            font-weight: bold;
          }

          /* Key Details */
          td:first-child {
            font-weight: bold;
            color: #343a40;
          }

          /* Responsive Adjustments */
          @media (max-width: 768px) {
            h1 {
              font-size: 1.5rem;
            }

            h2 {
              font-size: 1.25rem;
            }

            table {
              font-size: 0.8rem;
            }

            .modal-header {
              padding: 10px 15px;
            }
          }
        `);
    printWindow.document.write('</style></head><body>');
    printWindow.document.write('<div class="modal">');
    printWindow.document.write(modalContent);
    printWindow.document.write('</div></body></html>');
    printWindow.document.close();

    // Ensure the new window's document is fully loaded before printing
    printWindow.onload = () => {
      printWindow.focus();
      printWindow.print();
      printWindow.close();
    };
  };

  useEffect(() => {
    printModalContent();
  }, [order]);

  return (
    <Modal show={show} onHide={handleClose} fullscreen id="printSection">
      <Modal.Header closeButton>
        <Modal.Title>Trip Confirmation Voucher</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif', lineHeight: '1.6' }}>
          {/* Header Section */}
          <div style={{ textAlign: 'right', marginBottom: '2rem' }}>
            <img src="/ph-logo.png" width="250" alt="Pickup Hungary Logo" />
            <h1 style={{ color: '#eba23a', marginTop: '10px' }}>Pickup Hungary Trip</h1>
          </div>

          {/* Booking Info */}
          <div style={{ marginBottom: '1.5rem', borderBottom: '2px solid #dee2e6', paddingBottom: '10px' }}>
            <h2 style={{ color: '#343a40', fontSize: '1.5rem' }}>Booking Information</h2>
            <p>
              <strong>Booking ID:</strong>
              {' '}
              {order.booking_reference}
            </p>
            <p>
              <strong>Partner Name:</strong>
              {' '}
              {order.partner.name}
            </p>
            <p>
              <strong>Created Date:</strong>
              {' '}
              {new Date(order.created_at).toLocaleString()}
            </p>
            <p>
              <strong>Contact:</strong>
              {' '}
              <a href="mailto:info@pickuphungary.com" style={{ color: '#007bff' }}>
                info@pickuphungary.com
              </a>
            </p>
          </div>

          {/* Important Information */}
          <div style={{
            marginBottom: '2rem', background: '#f8f9fa', padding: '15px', borderRadius: '5px',
          }}
          >
            <h3 style={{ color: '#dc3545', fontSize: '1.25rem' }}>Important Information</h3>
            <ul style={{ paddingLeft: '20px' }}>
              <li>As per our cancellation policy, notify us no later than 24 hours before your trip.</li>
              <li>Your driver will be assigned the day prior to your booking.</li>
              <li>Drivers will contact you 30 minutes before your booking.</li>
              <li>
                <strong>Economy / Comfort category:</strong>
                {' '}
                Refer to our
                {' '}
                <a
                  href="https://d1i92x3swm0eql.cloudfront.net/Meeting%20at%20Airport_%20On-Call%20Service%20-%20CAR.pdf"
                  style={{ color: '#007bff' }}
                >
                  on-call meeting guide
                </a>
                {' '}
                for airport pickups.
              </li>
              <li>
                <strong>Business / Premium category:</strong>
                {' '}
                The driver will wait at the gate with a nameplate.
              </li>
              <li>
                In case of delays, drivers will wait up to 90 minutes free of charge. Notify us as soon as possible.
              </li>
            </ul>
          </div>

          {/* Trip Details */}
          <h2 style={{
            color: '#343a40', fontSize: '1.5rem', marginBottom: '1rem', pageBreakBefore: 'always',
          }}
          >
            Trip Details
          </h2>
          <Table striped bordered hover>
            <tbody>
              <tr>
                <td><strong>Order Status</strong></td>
                <td>{order.status}</td>
              </tr>
              <tr>
                <td><strong>Full Name</strong></td>
                <td>{order.full_name}</td>
              </tr>
              <tr>
                <td><strong>Email Address</strong></td>
                <td>{order.email_address}</td>
              </tr>
              <tr>
                <td><strong>Phone Number</strong></td>
                <td>{order.phone_number}</td>
              </tr>
              <tr>
                <td><strong>Date</strong></td>
                <td>{order.date_datetime && format(order.date_datetime)}</td>
              </tr>
              <tr>
                <td><strong>Return Date</strong></td>
                <td>{order.return_date_datetime && format(order.return_date_datetime)}</td>
              </tr>
              <tr>
                <td><strong>From</strong></td>
                <td>{order.destination_from?.address}</td>
              </tr>
              <tr>
                <td><strong>To</strong></td>
                <td>{order.destination_to?.address}</td>
              </tr>
              <tr>
                <td><strong>Flight Number</strong></td>
                <td>{order.flight_number}</td>
              </tr>
              <tr>
                <td><strong>Comments</strong></td>
                <td>{order.comments}</td>
              </tr>
              <tr>
                <td><strong>Total Passengers</strong></td>
                <td>{order.passengers.amount}</td>
              </tr>
              <tr>
                <td><strong>Adults</strong></td>
                <td>{order.passengers.adults}</td>
              </tr>
              <tr>
                <td><strong>Kids (Convertible Seat)</strong></td>
                <td>{order.passengers.convertible_seat}</td>
              </tr>
              <tr>
                <td><strong>Kids (Booster Seat)</strong></td>
                <td>{order.passengers.booster_seat}</td>
              </tr>
              <tr>
                <td><strong>Kids (Infant Carrier)</strong></td>
                <td>{order.passengers.infant_carrier}</td>
              </tr>
              <tr>
                <td><strong>Vehicle Category</strong></td>
                <td>
                  {`${order.category.name}, Max Capacity: ${order.category.capacity}, Max Luggage: ${order.category.max_luggage}`}
                </td>
              </tr>
              <tr>
                <td><strong>Price</strong></td>
                <td>
                  {thousandSeparator(order.price)}
                  {' '}
                  {order.currency}
                </td>
              </tr>
              <tr>
                <td><strong>Payment Type</strong></td>
                <td>{order.payment.type}</td>
              </tr>
            </tbody>
          </Table>
        </div>
      </Modal.Body>
    </Modal>
  );
}
