/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';

import {
  Button,
} from 'react-bootstrap';
// eslint-disable-next-line no-unused-vars
import _ from 'lodash';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import Filters from './Components/Filters';

import fetchOrdersWrapper from './Functions/fetchOrders';
import markAsCompletedWrapper from './Functions/markAsCompleted';
import archiveOrderWrapper from './Functions/archiveOrder';
import OrdersTable from './Components/OrdersTable';
import useModalState from '../../Hooks/useModalState';
import Error from '../../Components/Error';

dayjs.extend(utc);

function Orders() {
  const [orders, setOrders] = useState([]);
  const [filtered, setFiltered] = useState(false);

  const orderDetailsModal = useModalState();
  const operatorInputModal = useModalState();
  const orderInputModal = useModalState();
  const orderLineAdjustmentsModal = useModalState();
  const driverInputModal = useModalState();
  const tripConfirmationModal = useModalState();
  const pickupGuideModal = useModalState();
  const addInvoiceModal = useModalState();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showFilters, setShowFilters] = useState(false);
  const [filters, setFilters] = useState({ page: 1 });
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);

  const fetchOrders = () => {
    const enhancedFilters = { ...filters, page: filters?.page || page };
    return fetchOrdersWrapper({
      setFiltered,
      setIsLoading,
      setOrders,
      setTotalPages,
      setError,
    })(enhancedFilters);
  };

  const markAsCompleted = (order) => markAsCompletedWrapper({
    setIsLoading,
    fetchOrders,
  })(order);

  const archiveOrder = (order) => archiveOrderWrapper({
    setIsLoading,
    fetchOrders,
    setError,
  })(order);

  const sortFn = (column, desc = true) => {
    setFilters({ ...filters, sort: { [column]: desc ? 'desc' : 'asc' } });
  };

  const debouncedFetchOrders = _.debounce(() => {
    fetchOrders();
  }, 500);

  useEffect(() => {
    // Call the debounced version within useEffect
    debouncedFetchOrders();

    // Cleanup function to cancel any pending debounced call if the component
    // unmounts or before the next effect runs
    return () => debouncedFetchOrders.cancel();
  }, [filters]);

  useEffect(() => {
    setFilters((previousState) => ({ ...previousState, page }));
  }, [page]);

  if (error) {
    return (
      <Error message={error} />
    );
  }

  return (
    <>
      <div className="d-flex gap-2 w-100">
        <Button onClick={debouncedFetchOrders}><i className={`fa fa-sync ${isLoading ? 'fa-spin' : ''}`} /></Button>
        <Button onClick={() => setShowFilters(!showFilters)}><i className="fa fa-filter" /></Button>
        <Button onClick={() => orderInputModal.show()}>New Order</Button>
      </div>

      { showFilters && <Filters setFilters={setFilters} filters={filters} />}

      {filtered && <Button onClick={fetchOrders} className="btn-danger ms-2"><i className="fa fa-times" /></Button>}
      <OrdersTable
        orders={orders}
        page={page}
        totalPages={totalPages}
        setFilters={setFilters}
        archiveOrder={archiveOrder}
        isLoading={isLoading}
        fetchOrders={fetchOrders}
        markAsCompleted={markAsCompleted}
        setPage={setPage}
        sortFn={sortFn}
        orderLineAdjustmentsModal={orderLineAdjustmentsModal}
        orderInputModal={orderInputModal}
        orderDetailsModal={orderDetailsModal}
        operatorInputModal={operatorInputModal}
        driverInputModal={driverInputModal}
        tripConfirmationModal={tripConfirmationModal}
        pickupGuideModal={pickupGuideModal}
        addInvoiceModal={addInvoiceModal}
      />
    </>
  );
}

export default Orders;
