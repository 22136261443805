/* eslint-disable max-len */
import React from 'react';
import ButtonWithTooltip from '../../../Components/ButtonWithTooltip';
import isPastDate from '../../../Utils/isPastDate';

function OrderWarnings({
  order, assignOperator, markAsCompleted, assignDriver, assignPickupGuide,
}) {
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <div className="d-flex gap-1">
      {!order.operator_id && (
        <ButtonWithTooltip
          variant="danger"
          faClassName="fa-gear"
          message="Operator is missing"
          onClick={() => assignOperator(order)}
        />
      )}

      {(!order.operator_driver_id && !order.operator_car_id) && order.operator_id && (
        <ButtonWithTooltip
          variant="warning"
          faClassName="fa-car"
          message="Driver or car is missing"
          onClick={() => assignDriver(order)}
        />
      )}

      {/* {((order.payment?.type === 'Bank Transfer' || order.payment?.type === 'Mixed Payment') && !order.payment?.paid) && (
        <ButtonWithTooltip
          variant="warning"
          faClassName="fa-piggy-bank"
          message="Bank Transfer / Mixed Payment - mark as paid"
        />
      )} */}

      {(order.partner.driver_portal_enabled === 1 && !order.contentful_pickup_guide_id) && (
        <ButtonWithTooltip
          variant="danger"
          faClassName="fa-message-sms"
          message="Pickup Guide is not attached"
          onClick={() => assignPickupGuide(order)}
        />
      )}

      {
  (
    (
      isPastDate(order.date_datetime, 2)
      && !order.return_date_datetime
    )
    || (
      isPastDate(order.date_datetime, 2)
      && order.return_date_datetime
      && isPastDate(order.return_date_datetime, 2)
    )
  ) && order.status !== 'Complete' && (
    <ButtonWithTooltip
      variant="info"
      faClassName="fa-check"
      onClick={() => markAsCompleted(order)}
      message={`Mark Completed${order.email_address ? ' & Send Review Email' : ''}`}
    />
  )
}

    </div>
  );
}

export default OrderWarnings;
